import { createDefaultIncludedScripts } from '../../store/state';

const component = {
  computed: {}
};

const includedScripts = createDefaultIncludedScripts();
Object.keys(includedScripts)
  .filter(name => typeof includedScripts[name] === 'boolean')
  .forEach(name => {
    component.computed[name] = {
      get() {
        return this.$store.state.includedScripts[name];
      },
      set(value) {
        this.$store.commit('setIncludedScripts', { [name]: value });
        this.$store.commit('setIsSaved', false);
      }
    };
  });

export default component;
