import { mapState } from 'vuex';
import renderHtmlDocument from '../../utils/render-html-document';
import { GlobalEvents } from '../../utils/event-emitter';
import { addIncludedScripts, renderPreviewBody, protectSkeleton, runScripts } from '../../utils/render-preview-body';

export default {
  data() {
    return {
      iframe: null,
      iframeBody: null,
      protectedNodes: null
    };
  },

  computed: {
    ...mapState(['compiledCode', 'includedScripts', 'isCodeExpanded', 'userScriptList'])
  },

  watch: {
    isCodeExpanded() {
      if (this.$store.state.isCodeExpanded) { return; }

      this._renderContent();

      if (this.$store.state.previewSettings.reloadScriptsOnChange) {
        runScripts(this.iframeBody, this.protectedNodes);
      }
    },
    compiledCode() {
      if (this.$store.state.isCodeExpanded) { return; }

      this._renderContent();

      if (this.$store.state.previewSettings.reloadScriptsOnChange) {
        runScripts(this.iframeBody, this.protectedNodes);
      }
    },
    includedScripts() {
      this.reload();
    },
    userScriptList() {
      this.reload();
    }
  },

  methods: {

    reload() {
      const iframe = this._createIframe();
      if (this.iframe) {
        this.$refs.container.replaceChild(iframe, this.iframe);
        this.iframeBody = null;
      } else {
        this.$refs.container.appendChild(iframe);
      }

      this.iframe = iframe;
      this.iframe.contentWindow.navigateToSnippet = (snippetId) => {
        this.$emit('navigate', snippetId);
      };

      const contentDocument = iframe.contentDocument;
      contentDocument.open('text/html', 'replace');
      contentDocument.write(renderHtmlDocument(this.userScriptList));
      contentDocument.close();

      if (contentDocument.readyState === 'loading') {
        this.iframe.contentWindow.addEventListener('DOMContentLoaded', this._onIframeContentLoaded);
      } else {
        this._onIframeContentLoaded();
      }
    },


    _createIframe() {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('style', 'width: 100%; height: 100%;');

      return iframe;
    },


    _onIframeContentLoaded() {
      this.iframeBody = this.iframe.contentDocument.body;
      this.protectedNodes = protectSkeleton(this.iframeBody);
      addIncludedScripts(this.iframeBody, this.includedScripts);
      this._renderContent();
      runScripts(this.iframeBody, this.protectedNodes);
    },


    _renderContent() {
      if (!this.iframeBody) { return; }

      renderPreviewBody(this.iframeBody, this.compiledCode, this.protectedNodes, this.userScriptList);
    }

  },

  mounted() {
    this.reload();
    this.globalEmitter.subscribe(GlobalEvents.RELOAD_PREVIEW, this.reload);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe(GlobalEvents.RELOAD_PREVIEW, this.reload);
  }
};
