import { GlobalEvents } from '../../utils/event-emitter';

export default {
  computed: {
    scriptSnippetList() {
      return this.$store.state.scriptSnippetList;
    }
  },
  methods: {
    open() {
      this.$refs.dialog.open();
    },

    close() {
      this.$refs.dialog.close();
    },

    insertScriptSnippet(event) {
      const snippet = event.detail.data;
      const editor = this.$store.state.scriptEditor;
      const selection = editor.getSelection();
      const id = { major: 1, minor: 1 };
      const text = snippet.content;
      const insertTemplateOperation = { identifier: id, range: selection, text, forceMoveMarkers: true };

      editor.executeEdits('my-source', [insertTemplateOperation]);
      editor.focus();

      this.close();
    }
  },

  mounted() {
    this.globalEmitter.subscribe(GlobalEvents.INSERT_SCRIPT_SNIPPET_DIALOG_OPEN, this.open);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe(GlobalEvents.INSERT_SCRIPT_SNIPPET_DIALOG_OPEN, this.open);
  }
};
