export class IframeMessage {

  static GET_SNIPPET = 'edsPlayground:getSnippet';
  static RECEIVE_SNIPPET = 'edsPlayground:receiveSnippet';

  constructor(type, data) {
    this._type = type;
    this._data = data;
  }

  get type() {
    return this._type;
  }

  get data() {
    return this._data;
  }

  postOn(targetWindow, targetOrigin = '*') {
    const message = {
      type: this._type,
      data: this._data
    };
    targetWindow.postMessage(message, targetOrigin);
  }

  static create(type, data) {
    return new this(type, data);
  }

  static fromEvent(event) {
    const type = event.data?.type || '';
    const data = event.data?.data || null;
    return new this(type, data);
  }

}
