import { mapActions } from 'vuex';
import escapeHtml from '../../utils/escape-html.js';
import getAbsoluteUrl from '../../utils/get-absolute-url.js';


export default {
  data() {
    return {
      snippet: null,
      isShareWithCodeActive: false
    };
  },

  computed: {
    headline() {
      return this.snippet ? `Share snippet "${escapeHtml(this.snippet.name)}"` : '';
    },

    shareWithCodeUrl() {
      return this.snippet ? getAbsoluteUrl(`#?source_type=snippet&source_id=${this.snippet._id}`) : '';
    }
  },

  methods: {
    ...mapActions(['shareWithCode']),

    open(snippet) {
      this.snippet = snippet;
      this.isShareWithCodeActive = snippet.shared;
      this.$refs.dialog.open();
    },

    copyToClipboard() {
      if (!this.isShareWithCodeActive) { return; }
      this.$refs.urlInput.select();
      document.execCommand('copy');
      window.e.utils.openNotification({
        content: 'The link has been copied to your clipboard',
        placement: 'bottom-right',
        type: 'success',
        autoClose: true
      });
    },

    shareWithCode() {
      this.$store.dispatch('shareWithCode', { id: this.snippet._id, shared: this.isShareWithCodeActive });
    }
  },

  created() {
    this.globalEmitter.subscribe('openShareDialog', this.open);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe('openShareDialog', this.open);
  }
};
