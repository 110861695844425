import mongoid from 'mongoid-js';
import { getSessionId } from '../../utils/prototype-session-handler';
import EditorApp from '../editor-app/index.vue';
import PrototypeApp from '../prototype-app/index.vue';

export default {
  computed: {
    // TODO: refactor to get isPrototype from root app element
    isPrototype: () => {
      return new URL(window.location.href).pathname.startsWith('/prototype');
    }
  },
  methods: {
    _handlePrototypeSession() {
      const prototypeSessionId = getSessionId();

      if (!prototypeSessionId) {
        window.location.hash += `&prototype_session_id=${mongoid()}`;
      }
    }
  },
  mounted() {
    if (!this.isPrototype) { return; }

    this._handlePrototypeSession();
  },
  components: {
    EditorApp,
    PrototypeApp
  }
};
