import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      color: ({ compileError }) => compileError ? 'danger' : 'disabled',
      tooltip: ({ compileError }) => compileError ?
        `Error on ${compileError.line}:${compileError.column}: ${compileError.msg}` :
        'No errors'
    })
  }
};
