import { findIconSymbols } from './find-icon-symbols';

const protectIcons = (body, protectedSkeleton = []) => {
  const iconSymbolsRoot = findIconSymbols(body);

  if (iconSymbolsRoot && !protectedSkeleton.includes(iconSymbolsRoot)) {
    protectedSkeleton.push(iconSymbolsRoot);
  };
};

const clearSkeleton = (body, protectedSkeleton) => {
  Array.from(body.childNodes).forEach(node => {
    if (!protectedSkeleton.includes(node)) { node.remove(); }
  });
};

const createFragment = (body, html) => {
  const ownerDocument = body.ownerDocument;

  const template = ownerDocument.createElement('div');
  template.innerHTML = html;
  const fragment = ownerDocument.createDocumentFragment();
  Array.from(template.childNodes).forEach(node => fragment.appendChild(node));

  return fragment;
};

const createUserScript = (userScript) => {
  const script = document.createElement('script');
  script.innerHTML = userScript.content;
  script.dataset.isUserScript = true;

  return script;
};

export const protectSkeleton = body => Array.from(body.childNodes);

export const renderPreviewBody = (body, compiledCode, protectedSkeleton, userScriptList) => {
  if (!body) { return; }

  protectIcons(body, protectedSkeleton);
  clearSkeleton(body, protectedSkeleton);
  const fragment = createFragment(body, compiledCode);
  body.appendChild(fragment);

  const userScriptListAfter = userScriptList.filter(userScript => userScript.position === 'after');

  userScriptListAfter.forEach(userScript => {
    const script = createUserScript(userScript);
    body.insertAdjacentElement('beforeend', script);
  });
};

export const runScripts = (body, protectedSkeleton) => {
  [...body.querySelectorAll('script')]
    .filter(script => !protectedSkeleton.includes(script))
    .filter(script => !script.dataset.isUserScript)
    .forEach(script => runScript(body, script));
};

const runScript = (body, script) => {
  const ownerDocument = body.ownerDocument;
  const runnableScript = ownerDocument.createElement('script');
  [...script.attributes].forEach(({ name, value }) => runnableScript.setAttribute(name, value));
  runnableScript.innerHTML = script.innerHTML;
  script.replaceWith(runnableScript);
};

const addScript = (body, src) => {
  var ownerDocument = body.ownerDocument;
  var script = ownerDocument.createElement('script');
  script.src = src;
  ownerDocument.head.appendChild(script);
};

const addStyle = (body, src) => {
  var ownerDocument = body.ownerDocument;
  var style = ownerDocument.createElement('link');
  style.rel = 'stylesheet';
  style.href = src;
  ownerDocument.head.appendChild(style);
};

const addAlpineScript = (body) => {
  addScript(body, 'https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js');
  body.setAttribute('x-data', '{}');
};

const addUiFrameworkCharts = (body) => {
  addScript(body, 'https://client-version.cf.emarsys.net/ui-charts/latest/app.js');
};

const addUiFrameworkNavigation = (body) => {
  addStyle(body, 'https://client-version.cf.emarsys.net/ui/latest/css/navigation.css');
  addScript(body, 'https://client-version.cf.emarsys.net/ui/latest/js/navigation.js');
};

const addVCEPreview = (body) => {
  addStyle(body, 'https://client-version.cf.emarsys.net/ui/latest/css/services/vce.css');
  addScript(body, 'https://redirector.gservice.emarsys.net/vce-preview/latest/scripts/vce-preview.js');
};

export const addIncludedScripts = (body, includedScripts) => {
  if (includedScripts.alpineJs) {
    addAlpineScript(body);
  }
  if (includedScripts.uiFrameworkCharts) {
    addUiFrameworkCharts(body);
  }
  if (includedScripts.uiFrameworkNavigation) {
    addUiFrameworkNavigation(body);
  }
  if (includedScripts.vcePreview) {
    addVCEPreview(body);
  }
};
