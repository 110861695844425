import { mapGetters, mapState } from 'vuex';
import ErrorBadge from '../error-badge/index.vue';

export default {
  components: { ErrorBadge },

  data() {
    return {
      editor: null,
      isCreateMode: false
    };
  },

  computed: {
    ...mapState(['activeJsonDataId']),
    ...mapGetters(['activeJsonData']),

    jsonDataList() {
      return this.$store.state.jsonDataList;
    },
    isEditMode() {
      return !!this.$store.state.activeJsonDataId || this.isCreateMode;
    },
    currentData() {
      return this.activeJsonData ? this.activeJsonData : {
        name: 'New JSON Data',
        content: ''
      };
    }
  },

  watch: {
    activeJsonDataId() {
      this.editor.setValue(this.currentData.content);
    }
  },

  methods: {
    onResize() {
      this.editor.layout();
    },

    create() {
      const data = {
        _id: false,
        name: 'New JSON Data',
        content: ''
      };

      this._initEditor(data);
    },

    edit(event) {
      this._initEditor(event.detail.data);
    },

    remove(event) {
      const data = event.detail.data;
      window.e.utils.openDestructiveConfirmationDialog({
        headline: 'Delete JSON Data',
        content: `Are you sure you want to delete the JSON Data "${data.name}"?`,
        confirm: {
          label: 'Delete',
          callback: () => this.$store.dispatch('deleteJsonData', data._id)
        }
      });
    },

    onBackButtonClick() {
      if (this.isSaveDisabled) { return; }

      this.isCreateMode = false;
      this.$store.commit('setActiveJsonDataId', null);
    },

    onSaveButtonClick() {
      const data = {
        ...this.currentData,
        name: this.$refs.inplaceEditor.value,
        content: this.editor.getValue()
      };

      this.$store.dispatch('saveJsonData', data);
    },

    _initEditor(data) {
      if (data._id === false) {
        this.isCreateMode = true;
      }

      this.$store.commit('setActiveJsonDataId', data._id);
      this.editor.setValue(data.content);

      this.$nextTick(() => {
        this.editor.layout();
      });
    }
  },

  mounted() {
    if (this.editor) { return; }

    this.editor = window.monaco.editor.create(this.$refs.container, {
      language: 'json',
      value: this.currentData.content,
      minimap: { enabled: false },
      tabSize: 2
    });
  }
};
