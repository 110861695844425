import { mapActions } from 'vuex';

export default {
  computed: {
    snippetsLoaded() {
      return !!this.$store.state.snippets;
    },
    snippets() {
      return this.$store.state.snippets.map((snippet) =>
        ({
          ...snippet,
          disabled: !snippet.shared
        })
      ).filter((snippet) => snippet._id !== this.$store.state.activeSnippetId);
    }
  },

  methods: {
    ...mapActions(['refreshSnippets']),

    open() {
      this.$refs.dialog.open();
    },

    insert(snippetId) {
      const htmlFormat = `navigateToSnippet('${snippetId}')`;
      const pugFormat = `navigateToSnippet(\\\'${snippetId}\\\')`;
      const isPug = this.$store.state.language === 'pug';

      this.globalEmitter.emit('insertNavigateFunction', isPug ? pugFormat : htmlFormat);

      this.$refs.dialog.close();
    }
  },

  created() {
    this.globalEmitter.subscribe('openInsertSnippetDialog', this.open);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe('openInsertSnippetDialog', this.open);
  }
};
