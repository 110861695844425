import mongoid from 'mongoid-js';
import sendRequest from '../utils/send-request';
import isSiteTrusted from '../utils/is-site-trusted';
import { getSessionId, isPrototypeSessionTrusted } from '../utils/prototype-session-handler';
import { GlobalEvents } from '../utils/event-emitter';
import { getSnippetCodeFromIframe, IframeResponseTimeoutError } from '../utils/get-snippet-from-iframe';
import { createDefaultIncludedScripts } from './state';


export const createActions = ({ globalEmitter }) => ({
  reloadPreview() {
    globalEmitter.emit(GlobalEvents.RELOAD_PREVIEW);
  },

  promptNewName({ getters }) {
    if (!getters.isSignedIn) { return globalEmitter.emit(GlobalEvents.PROMPT_LOGIN_FOR_SAVE); }

    const newName = getters.activeSnippet ? `Copy of ${getters.activeSnippet.name}` : '';
    globalEmitter.emit(GlobalEvents.PROMPT_NEW_NAME, { newName });
  },

  navigateToSnippet({ dispatch }, id) {
    let newHash = id ? `?source_type=snippet&source_id=${id}` : '';

    const prototypeSessionId = getSessionId();

    if (prototypeSessionId) {
      newHash += `&prototype_session_id=${prototypeSessionId}`;
    }

    if (document.location.hash.slice(1) === newHash) {
      id ? dispatch('fetchSnippet', id) : dispatch('newSnippet');
    }
    document.location.hash = newHash;
  },

  async clearSession({ commit }) {
    await sendRequest({ resource: '/api/signout' });
    localStorage.removeItem('session');
    commit('setSession', { expires: null, userId: null, name: '' });
    commit('setActiveSnippetId', null);
    commit('setActiveJsonDataId', null);
    commit('setActiveScriptSnippetId', null);
    commit('setActiveUserScriptId', null);
  },

  uiThemeChange({}, event) {
    globalEmitter.emit(GlobalEvents.UI_THEME_CHANGE, event.detail.colorTheme);
  },

  async refreshSnippets({ commit }) {
    const { data: snippets } = await sendRequest({ resource: '/api/snippets' });
    commit('setSnippets', snippets);
  },

  async deleteSnippet({ commit }, id) {
    await sendRequest({ resource: `/api/snippets/${id}`, method: 'DELETE' });
    commit('removeSnippet', id);
  },

  async applyHashData({ state, commit, dispatch }, parameters) {
    switch (parameters['source_type']) {
      case 'snippet':
        if (state.activeSnippetId === parameters['source_id']) { return; }
        dispatch('fetchSnippet', parameters['source_id']);
        break;
      case 'url':
        commit('setLanguage', parameters['language']);
        await dispatch('fetchFromUrl', parameters['source_url']);
        break;
      case 'iframe':
        dispatch('fetchFromIframe', { url: parameters['source_url'], sourceId: parameters['source_id'] });
        break;
      default:
        dispatch('newSnippet');
    }

    if (!parameters.scripts) { return; }

    let activeScripts = {};

    parameters.scripts.split(',').forEach(key => {
      activeScripts = {
        ...activeScripts,
        [key]: true
      };
    });

    commit('setIncludedScripts', activeScripts);
  },

  async fetchSnippet({ state, dispatch }, id) {
    const cachedSnippet = state.snippets && state.snippets.find(snippet => snippet._id === id);
    try {
      const activeSnippet = cachedSnippet ||
        (await sendRequest({ resource: `api/snippets/${id}`, auth: true })).data;
      dispatch('applySnippet', activeSnippet);
    } catch (error) {
      dispatch('handleRequestError', error);
    }
  },

  async fetchFromUrl({ commit, dispatch }, url) {
    try {
      const sourceCode = await sendRequest({ resource: url, responseType: 'text' });
      commit('setIsPreviewSafe', isSiteTrusted(url));
      commit('setActiveSnippetId', null);
      commit('setSourceCode', sourceCode.trim());
      commit('setIsSaved', false);
      dispatch('reloadPreview');
    } catch (error) {
      dispatch('handleRequestError', error);
    }
  },

  async fetchFromIframe({ commit, dispatch }, { url, sourceId }) {
    try {
      const snippet = await getSnippetCodeFromIframe(url, sourceId);
      commit('setIsPreviewSafe', isSiteTrusted(url));
      commit('setActiveSnippetId', null);
      commit('setLanguage', snippet.language);
      commit('setSourceCode', snippet.sourceCode);
      commit('setIsSaved', false);
      commit('setName', snippet.name);
      dispatch('reloadPreview');
    } catch (error) {
      const message = error instanceof IframeResponseTimeoutError ?
        'Request timed out!' :
        'Something went wrong!';
      window.e.utils.openNotification({ content: message, type: 'danger', autoClose: true });
      console.error(error);
    }
  },

  applySnippet({ state, commit, dispatch }, snippet) {
    const isOwned = state.session && state.session.userId === snippet.userId;
    commit('setIsPreviewSafe', isOwned || isPrototypeSessionTrusted());
    commit('setActiveSnippetId', isOwned ? snippet._id : null);
    commit('setLanguage', snippet.language);
    commit('setName', snippet.name);
    commit('setIncludedScripts', { ...createDefaultIncludedScripts(), ...snippet.includedScripts });
    commit('setIsSaved', isOwned);
    commit('setJsonDataList', snippet.jsonDataList || []);
    commit('setActiveJsonDataId', null);
    commit('setActiveScriptSnippetId', null);
    commit('setUserScriptList', snippet.userScriptList || []);
    commit('setActiveUserScriptId', null);
    commit('setSourceCode', snippet.sourceCode);
    commit('setSnippetIsDirty', false);
    dispatch('reloadPreview');
  },

  newSnippet({ commit, dispatch }) {
    commit('setActiveSnippetId', null);
    commit('setName', '');
    commit('setIncludedScripts', { ...createDefaultIncludedScripts() });
    commit('setIsSaved', false);
    commit('setIsPreviewSafe', true);
    commit('setJsonDataList', []);
    commit('setActiveJsonDataId', null);
    commit('setActiveScriptSnippetId', null);
    commit('setUserScriptList', []);
    commit('setActiveUserScriptId', null);
    commit('setSourceCode', '');
    commit('setSnippetIsDirty', false);
    dispatch('reloadPreview');
  },

  async shareWithCode({ commit, dispatch }, { id, shared }) {
    try {
      await sendRequest({
        resource: `api/snippets/${id}/shared`,
        auth: true,
        method: 'PUT',
        body: { shared }
      });
      commit('setShared', { id, shared });
    } catch (error) {
      dispatch('handleRequestError', error);
    }
  },

  handleRequestError(_, error) {
    const statusMap = {
      401: 'Not authorized!',
      404: 'Snippet not found!'
    };
    const errorMessage = statusMap[error.status] || 'Something went wrong!';
    window.e.utils.openNotification({ content: errorMessage, type: 'danger', autoClose: true });
    console.error(error);
  },

  openLogin() {
    const width = 600;
    const height = 700;
    const left = window.outerWidth / 2 + window.screenLeft - (width / 2);
    const top = window.outerHeight / 2 + window.screenTop - (height / 2);
    const options = `width=${width},height=${height},left=${left},top=${top},toolbar=no,menubar=no`;
    window.open('/authenticate/google/signin', '', options);
  },

  async save({ state, getters, commit, dispatch }) {
    if (getters.isSaveDisabled) { return; }
    if (!getters.activeSnippet) { return dispatch('promptNewName'); }
    if (!getters.isSignedIn) { return globalEmitter.emit(GlobalEvents.PROMPT_LOGIN_FOR_SAVE); }

    commit('setIsSaveLoading', true);

    const body = {
      snippet: {
        ...getters.activeSnippet,
        name: state.name,
        sourceCode: state.sourceCode,
        language: state.language,
        includedScripts: state.includedScripts,
        jsonDataList: state.jsonDataList,
        userScriptList: state.userScriptList
      }
    };

    const resource = `/api/snippets/${getters.activeSnippet._id}`;
    const { data: newSnippet } = await sendRequest({ resource, method: 'PUT', auth: true, body })
      .catch(() => {}); // sophisticate error handling

    commit('setIsSaveLoading', false);
    commit('upsertSnippet', newSnippet);
    commit('setIsSaved', true);
    commit('setSnippetIsDirty', false);
  },

  async saveAs({ state, commit, dispatch }, newName) {
    commit('setIsSaveLoading', true);

    const body = {
      snippet: {
        name: newName,
        sourceCode: state.sourceCode,
        language: state.language,
        includedScripts: state.includedScripts,
        jsonDataList: state.jsonDataList,
        userScriptList: state.userScriptList
      }
    };

    const { data: newSnippet } = await sendRequest({ resource: '/api/snippets', method: 'POST', auth: true, body })
      .catch(() => {}); // sophisticate error handling

    commit('setIsSaveLoading', false);
    commit('upsertSnippet', newSnippet);
    commit('setSnippetIsDirty', false);
    dispatch('navigateToSnippet', newSnippet._id);
  },

  saveJsonData({ state, commit }, data) {
    const jsonData = {
      _id: state.activeJsonDataId || mongoid(),
      name: data.name,
      content: data.content,
      createdAt: data.createdAt
    };

    commit('upsertJsonData', jsonData);
    commit('setSourceCode', state.sourceCode);
    commit('setIsSaved', false);
    commit('setSnippetIsDirty', true);
  },

  deleteJsonData({ commit }, id) {
    commit('removeJsonData', id);
  },

  async refreshScriptSnippets({ commit }) {
    const { data: scriptSnippets } = await sendRequest({ resource: '/api/script-snippets' });
    commit('setScriptSnippets', scriptSnippets);
  },

  async saveScriptSnippet({ state, commit }, data) {
    const body = {
      _id: state.activeScriptSnippetId || null,
      name: data.name,
      content: data.content,
      createdAt: data.createdAt
    };

    if (!state.activeScriptSnippetId) {
      const { data: newSnippet } = await sendRequest({ resource: '/api/script-snippets', method: 'POST', auth: true, body })
        .catch(() => {});

      commit('upsertScriptSnippet', newSnippet);
    } else {
      const resource = `/api/script-snippets/${state.activeScriptSnippetId}`;
      const { data: newSnippet } = await sendRequest({ resource, method: 'PUT', auth: true, body })
        .catch(() => {});

      commit('upsertScriptSnippet', { ...newSnippet, _id: state.activeScriptSnippetId });
    }
  },

  async deleteScriptSnippet({ commit }, id) {
    await sendRequest({ resource: `/api/script-snippets/${id}`, method: 'DELETE' });
    commit('removeScriptSnippet', id);
  },

  saveUserScript({ state, commit }, data) {
    const userScript = {
      _id: state.activeUserScriptId || mongoid(),
      name: data.name,
      content: data.content,
      position: data.position,
      createdAt: data.createdAt
    };

    commit('upsertUserScript', userScript);
    commit('setSourceCode', state.sourceCode);
    commit('setIsSaved', false);
    commit('setSnippetIsDirty', true);
  },

  deleteUserScript({ commit }, id) {
    commit('removeUserScript', id);
  }
});
