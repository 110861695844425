export const findIconSymbols = (container) => {
  const symbol = container.querySelector('svg symbol[id]+symbol[id]');
  return getParent(symbol, isHidden) && getParent(symbol, isChildOf(container));
};


const getParent = (element, predicate) => {
  let current = element?.parentNode;
  while (current && !predicate(current)) { current = current.parentNode; }
  return current;
};


const isHidden = (element) => element?.style?.display === 'none';


const isChildOf = (parent) => (element) => element?.parentNode === parent;
