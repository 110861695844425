import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['activeSnippet']),
    isShareEnabled() {
      return !!this.activeSnippet;
    }
  },
  methods: {
    share() {
      this.globalEmitter.emit('openShareDialog', this.activeSnippet);
    }
  }
};
