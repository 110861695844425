export default {
  computed: {
    jsonDataList() {
      return this.$store.state.jsonDataList;
    }
  },

  methods: {
    open() {
      this.$refs.dialog.open();
    },

    insert(jsonDataId) {
      const htmlFormat = `#{jsonDataList['${jsonDataId}']}`;
      const pugFormat = `#{jsonDataList[\\\'${jsonDataId}\\\']}`;
      const isPug = this.$store.state.language === 'pug';

      this.globalEmitter.emit('insertJsonDataFunction', isPug ? pugFormat : htmlFormat);

      this.$refs.dialog.close();
    }
  },

  created() {
    this.globalEmitter.subscribe('openInsertJsonDataDialog', this.open);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe('openInsertJsonDataDialog', this.open);
  }
};
