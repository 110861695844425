import { mapState } from 'vuex';
import { GlobalEvents } from '../../utils/event-emitter';

export default {
  data() {
    return {
      newName: ''
    };
  },

  computed: {
    ...mapState(['isSaveLoading']),

    isSaveAsDisabled() {
      return !this.newName || this.isSaveLoading;
    }
  },

  methods: {
    open({ newName }) {
      this.newName = newName;
      this.$refs.dialog.open();
    },

    close() {
      this.$refs.dialog.close();
    },

    async saveAs() {
      await this.$store.dispatch('saveAs', this.newName);
      this.close();
    }
  },

  mounted() {
    this.globalEmitter.subscribe(GlobalEvents.PROMPT_NEW_NAME, this.open);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe(GlobalEvents.PROMPT_NEW_NAME, this.open);
  }
};
