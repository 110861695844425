import { mapState } from 'vuex';
import renderHtmlDocument from '../../utils/render-html-document';
import { addIncludedScripts, renderPreviewBody, protectSkeleton, runScripts } from '../../utils/render-preview-body';

export default {
  data() {
    return {
      previewWindow: null,
      previewBody: null,
      previewHead: null,
      protectedSkeleton: null
    };
  },
  computed: {
    ...mapState(['compiledCode', 'includedScripts', 'userScriptList'])
  },
  watch: {
    compiledCode() {
      if (!this._hasPreviewWindow() || !this.previewBody) { return; }

      this._writePreview();

      if (this.$store.state.previewSettings.reloadScriptsOnChange) {
        runScripts(this.previewBody, this.protectedSkeleton);
      }
    },
    includedScripts() {
      if (!this._hasPreviewWindow() || !this.previewBody) { return; }
      this._reload();
    },
    userScriptList() {
      if (!this._hasPreviewWindow() || !this.previewBody) { return; }
      this._reload();
    }
  },
  methods: {
    openInNewWindow() {
      if (this._hasPreviewWindow()) { return; }

      this.previewWindow = window.open();
      this._reload();
    },
    _reload() {
      const newDocument = renderHtmlDocument(this.userScriptList);
      this.previewWindow.document.open();
      this.previewWindow.document.write(newDocument);
      this.previewWindow.document.close();

      this.previewWindow.addEventListener('load', () => {
        this.previewBody = this.previewWindow.document.body;
        this.protectedSkeleton = protectSkeleton(this.previewBody);
        addIncludedScripts(this.previewBody, this.includedScripts);
        this._writePreview();
        runScripts(this.previewBody, this.protectedSkeleton);
      });
    },
    _hasPreviewWindow() {
      return this.previewWindow && !this.previewWindow.closed;
    },
    _writePreview() {
      renderPreviewBody(
        this.previewBody,
        this.$store.state.compiledCode,
        this.protectedSkeleton,
        this.userScriptList
      );
    }
  }
};
