import { mapActions } from 'vuex';


export default {
  data() {
    return {
      mixins: {
        session: {
          lastSessionString: '',
          timeoutId: null
        }
      }
    };
  },

  methods: {
    _updateSession() {
      const storedSession = localStorage.getItem('session');
      if (storedSession === this.mixins.session.lastSessionString) { return; }
      this.mixins.session.lastSessionString = storedSession;

      if (!storedSession) { return this._clearSession(); }

      try {
        const session = JSON.parse(storedSession);
        const now = Date.now();
        const isValidSession = session.expires > now;
        if (!isValidSession) { return this._clearSession(); }

        this.$store.commit('setSession', {
          expires: session.expires,
          userId: session.user.id,
          name: session.user.name
        });

        this._checkSession();
        this._refreshSnippets();
        this._refreshScriptSnippets();
      } catch (error) {
        console.error(error);
      }
    },

    _checkSession() {
      clearTimeout(this.mixins.session.timeoutId);
      if (!this.$store.getters.isSignedIn) { return; }

      const expiresIn = this.$store.state.session.expires - Date.now();
      const isValid = expiresIn > 0;
      if (!isValid) {
        return this._clearSession();
      }

      const oneHour = 60 * 60 * 1000;
      const nextCheck = expiresIn > oneHour ? oneHour : expiresIn;
      this.mixins.session.timeoutId = setTimeout(this._checkSession, nextCheck);
    },

    _listenToStorageChanges() {
      window.addEventListener('storage', this._updateSession);
    },

    ...mapActions({ _clearSession: 'clearSession', _refreshSnippets: 'refreshSnippets', _refreshScriptSnippets: 'refreshScriptSnippets' })
  },

  mounted() {
    this._updateSession();
    this._listenToStorageChanges();
  }
};
