export const getters = {
  snippetIsDirty(state) {
    return state.snippetIsDirty;
  },

  isSignedIn(state) {
    return !!state.session.userId;
  },

  activeSnippet(state) {
    return state.snippets && state.snippets.find(snippet => snippet._id === state.activeSnippetId);
  },

  activeJsonData(state) {
    return state.jsonDataList && state.jsonDataList.find(jsonData => jsonData._id === state.activeJsonDataId);
  },

  activeUserScript(state) {
    return state.userScriptList && state.userScriptList.find(userScript => userScript._id === state.activeUserScriptId);
  },

  activeScriptSnippet(state) {
    return state.scriptSnippetList && state.scriptSnippetList.find(scriptSnippet => scriptSnippet._id === state.activeScriptSnippetId);
  },

  isSaveDisabled(state) {
    return state.isSaved || state.isSaveLoading;
  },

  ctrlOrCmd() {
    return navigator.platform.startsWith('Mac') ? '&#8984;' : 'Ctrl';
  },

  hidePreviewBorder(state) {
    return !state.previewSettings.showBorder;
  }
};
