import { GlobalEvents } from '../../utils/event-emitter';
import GoogleSignInButton from '../google-sign-in-button/index.vue';

export default {
  components: { GoogleSignInButton },

  methods: {
    open() {
      this.$refs.dialog.open();
    },

    close() {
      this.$refs.dialog.close();
    }
  },

  mounted() {
    this.globalEmitter.subscribe(GlobalEvents.PROMPT_LOGIN_FOR_SAVE, this.open);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe(GlobalEvents.PROMPT_LOGIN_FOR_SAVE, this.open);
  }
};
