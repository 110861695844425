import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      prototypeURL: ''
    };
  },
  computed: {
    ...mapGetters(['activeSnippet']),
    ...mapState(['name']),
    isPrototypeEnabled() {
      return this.activeSnippet ? this.activeSnippet.shared : this.name;
    },
    linkURL() {
      return this.isPrototypeEnabled ? this.prototypeURL : null;
    }
  },
  methods: {
    recalculatePrototypeURL() {
      const currentURL = new URL(window.location.href);
      currentURL.pathname = '/prototype';
      this.prototypeURL = currentURL.toString();
    },
    copyPrototypeLinkToClipboard() {
      navigator.clipboard.writeText(this.prototypeURL);
      window.e.utils.openNotification({
        content: 'The link has been copied to your clipboard',
        placement: 'bottom-right',
        type: 'success',
        autoClose: true
      });
    }
  },
  mounted() {
    this.recalculatePrototypeURL();
    window.addEventListener('hashchange', this.recalculatePrototypeURL);
  }
};
