import Vue from 'vue';
import PreviewSettingsPopup from '../preview-settings-popup/index.vue';

export default {
  data() {
    return {
      popup: null
    };
  },
  methods: {
    openPreviewSettings() {
      if (!this.popup) {
        const PreviewSettingsPopupClass = Vue.extend(PreviewSettingsPopup);
        const popupInstance = new PreviewSettingsPopupClass({ parent: this });
        popupInstance.$mount();

        this.popup = window.e.utils.createPopup(this.$refs.previewButton, popupInstance.$el);
      }

      this.popup.open({ preventAutoFocus: false });
    }
  },
  components: { PreviewSettingsPopup }
};
