import Vue from 'vue';
import Vuex from 'vuex';
import { createDefaultState } from './state';
import { getters } from './getters';
import { mutations } from './mutations';
import { createActions } from './actions';

Vue.use(Vuex);


export default ({ globalEmitter }) => new Vuex.Store({
  state: createDefaultState(),
  getters,
  mutations,
  actions: createActions({ globalEmitter })
});
