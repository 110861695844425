import { mapActions } from 'vuex';


export default {
  methods: {
    ...mapActions(['save', 'reloadPreview']),

    _onKeyDown(event) {
      const crtlOrCmd = (event.ctrlKey || event.metaKey);

      if (crtlOrCmd && event.key === 's') {
        event.preventDefault();
        this.save();
      } else if (crtlOrCmd && event.key === 'r') {
        event.preventDefault();
        this.reloadPreview();
      }
    }
  },

  mounted() {
    window.addEventListener('keydown', this._onKeyDown);
  }
};
