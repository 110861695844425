import EditorPanel from '../editor-panel/index.vue';
import SnippetsPanel from '../snippets-panel/index.vue';
import ScriptsPanel from '../scripts-panel/index.vue';
import JsonDataPanel from '../json-data-panel/index.vue';
import ScriptEditorPanel from '../script-editor-panel/index.vue';
import ScriptSnippetsPanel from '../script-snippets-panel/index.vue';

export default {
  components: { EditorPanel, SnippetsPanel, ScriptsPanel, JsonDataPanel, ScriptEditorPanel, ScriptSnippetsPanel }
};
