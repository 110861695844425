const getTrustedSessions = () => {
  let trustedSessions = [];

  try {
    trustedSessions = JSON.parse(window.sessionStorage.getItem('trustedPrototypeSessions')) || [];
  } catch (error) {
    console.error(error);
  }

  return trustedSessions;
};

export const getSessionId = () => {
  const sessionId = window.location.hash.slice(1).match(/prototype_session_id=([a-z0-9]+)/);
  return sessionId ? sessionId[1] : false;
};

export const setTrustedSessions = () => {
  const sessionId = getSessionId();

  if (!sessionId) { return; }

  const trustedSessions = getTrustedSessions();
  trustedSessions.push(sessionId);

  window.sessionStorage.setItem('trustedPrototypeSessions', JSON.stringify(trustedSessions));
};

export const isPrototypeSessionTrusted = () => {
  const sessionId = getSessionId();
  const trustedSessions = getTrustedSessions();

  return trustedSessions.length && sessionId ?
    trustedSessions.some(session => session === sessionId) :
    false;
};
