import config from '../../config';
import renderTemplate from './template';

const uiFrameworkUrl = /^(.*?)\/?$/.exec(config.uiFrameworkUrl)[1];

export default userScriptList => {
  const userScriptListBefore = userScriptList.filter(userScript => userScript.position === 'before');

  return renderTemplate({ uiFrameworkUrl, userScriptListBefore });
};
