import { mapState, mapActions } from 'vuex';

import config from '../../config';
import sessionMixin from '../../mixins/session.js';
import hashHandlerMixin from '../../mixins/hash-handler';
import PreviewWarning from '../preview-warning/index.vue';
import UiKitPreview from '../ui-kit-preview/index.vue';

export default {
  mixins: [sessionMixin, hashHandlerMixin],
  computed: {
    codeEditorURL: () => {
      const currentURL = new URL(window.location.href);
      currentURL.pathname = '';
      return currentURL.toString();
    },
    ...mapState(['isPreviewSafe', 'name'])
  },
  watch: {
    name() {
      document.title = [this.$store.state.name, config.pageTitle].filter(part => part).join(' - ');
    }
  },
  methods: {
    ...mapActions(['navigateToSnippet'])
  },
  components: {
    UiKitPreview,
    PreviewWarning
  }
};
