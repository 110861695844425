import { mapGetters, mapState, mapActions } from 'vuex';
import GoogleSignInButton from '../google-sign-in-button/index.vue';

export default {
  components: { GoogleSignInButton },

  computed: {
    ...mapGetters(['isSignedIn']),
    ...mapState({
      userName: state => state.session.name
    })
  },

  methods: {
    ...mapActions(['clearSession', 'uiThemeChange'])
  }
};
