import Vue from 'vue';
import App from './components/app/index.vue';
import createStore from './store';
import { EventEmitter } from './utils/event-emitter';

const globalEmitter = new EventEmitter();

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^e-/];
Vue.prototype.globalEmitter = globalEmitter;

new Vue({
  store: createStore({ globalEmitter }),
  render: h => h(App)
}).$mount('#app');
