import { mapState, mapGetters, mapActions } from 'vuex';

export default {

  computed: {
    ...mapState(['isSaveLoading']),
    ...mapGetters(['isSignedIn', 'ctrlOrCmd', 'isSaveDisabled'])
  },

  methods: {
    ...mapActions(['save', 'promptNewName'])
  }
};
