import { createDefaultState } from '../../store/state';

const component = {
  computed: {}
};

const state = createDefaultState();
Object.keys(state.previewSettings).forEach(name => {
  component.computed[name] = {
    get() {
      return this.$store.state.previewSettings[name];
    },
    set(value) {
      this.$store.commit(`setPreviewSettings${name.charAt(0).toUpperCase()}${name.substring(1)}`, value);
    }
  };
});

export default component;
