import ErrorBadge from '../error-badge/index.vue';
import MonacoEditor from '../monaco-editor/index.vue';
import LanguageSwitch from '../language-switch/index.vue';
import ExpandCodeButton from '../expand-code-button/index.vue';
import OpenSnippetDialogButton from '../open-snippet-dialog-button/index.vue';
import OpenJsonDataDialogButton from '../open-json-data-dialog-button/index.vue';
import InsertComponentButton from '../insert-component-button/index.vue';
import InsertSnippetDialog from '../insert-snippet-dialog/index.vue';
import InsertJsonDataDialog from '../insert-json-data-dialog/index.vue';

export default {
  computed: {
    isPug() {
      return this.$store.state.language === 'pug';
    }
  },

  components: {
    ErrorBadge,
    LanguageSwitch,
    MonacoEditor,
    ExpandCodeButton,
    InsertComponentButton,
    OpenSnippetDialogButton,
    OpenJsonDataDialogButton,
    InsertSnippetDialog,
    InsertJsonDataDialog
  }
};
