import { mapMutations } from 'vuex';
import { setTrustedSessions } from '../../utils/prototype-session-handler';

export default {
  methods: {
    ...mapMutations(['setIsPreviewSafe']),
    allowPreview() {
      this.setIsPreviewSafe(true);
      setTrustedSessions();
    }
  }
};
