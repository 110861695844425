import { mapGetters, mapState } from 'vuex';
import GoogleSignInButton from '../google-sign-in-button/index.vue';

export default {
  components: { GoogleSignInButton },

  data() {
    return {
      editor: null,
      isCreateMode: false
    };
  },

  computed: {
    ...mapState(['activeScriptSnippetId']),
    ...mapGetters(['isSignedIn', 'activeScriptSnippet']),

    scriptSnippetList() {
      return this.$store.state.scriptSnippetList;
    },
    isEditMode() {
      return !!this.$store.state.activeScriptSnippetId || this.isCreateMode;
    },
    currentData() {
      return this.activeScriptSnippet ? this.activeScriptSnippet : {
        name: 'New Snippet',
        content: ''
      };
    }
  },

  watch: {
    activeScriptSnippetId() {
      this.editor.setValue(this.currentData.content);
    }
  },

  methods: {
    onResize() {
      this.editor.layout();
    },

    create() {
      const data = {
        _id: false,
        name: 'New Snippet',
        content: ''
      };

      this._initEditor(data);
    },

    edit(event) {
      this._initEditor(event.detail.data);
    },

    remove(event) {
      const data = event.detail.data;
      window.e.utils.openDestructiveConfirmationDialog({
        headline: 'Delete Snippet',
        content: `Are you sure you want to delete the Snippet "${data.name}"?`,
        confirm: {
          label: 'Delete',
          callback: () => this.$store.dispatch('deleteScriptSnippet', data._id)
        }
      });
    },

    onBackButtonClick() {
      if (this.isSaveDisabled) { return; }

      this.isCreateMode = false;
      this.$store.commit('setActiveScriptSnippetId', null);
    },

    onSaveButtonClick() {
      const data = {
        ...this.currentData,
        name: this.$refs.inplaceEditor.value,
        content: this.editor.getValue()
      };

      this.$store.dispatch('saveScriptSnippet', data);
    },

    _initEditor(data) {
      if (data._id === false) {
        this.isCreateMode = true;
      }

      this.$store.commit('setActiveScriptSnippetId', data._id);
      this.editor.setValue(data.content);

      this.$nextTick(() => {
        this.editor.layout();
      });
    }
  },

  mounted() {
    if (this.editor) { return; }

    this.editor = window.monaco.editor.create(this.$refs.container, {
      language: 'javascript',
      value: this.currentData.content,
      minimap: { enabled: false },
      tabSize: 2
    });
  }
};
