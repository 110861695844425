import { mapActions } from 'vuex';


export default {

  methods: {
    _applyCurrentHash() {
      const parameters = this._getCurrentHashData();
      this._applyHashData(parameters);
    },


    _getCurrentHashData() {
      const hash = window.location.hash.slice(1);
      const urlSearchParams = new URLSearchParams(hash);
      return Object.fromEntries(urlSearchParams);
    },


    _listenToHashChanges() {
      window.addEventListener('hashchange', this._applyCurrentHash);
    },


    ...mapActions({ _applyHashData: 'applyHashData' })
  },


  created() {
    this._applyCurrentHash();
    this._listenToHashChanges();
  }

};
