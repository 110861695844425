import { RequestError } from '../errors/request-error.js';


export default async ({ resource, method = 'GET', auth, query, body, responseType = 'json' }) => {
  const options = { method };
  if (auth) {
    options.credentials = 'same-origin';
  }
  if (body) {
    options.headers = { 'Content-Type': 'application/json' };
    options.body = JSON.stringify(body);
  }
  const queryString = getQueryString(query);
  const response = await fetch(`${resource}${queryString}`, options);
  if (!response.ok) { throw new RequestError(response); }
  if (response.status === 200) { return await responseType === 'json' ? response.json() : response.text(); }
};


const getQueryString = (query) => {
  if (!query) { return ''; }
  const queryString = Object.entries(query)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${queryString}`;
};
