/* eslint-disable indent */

export default (html) => {
  const parser = new DOMParser();
  const parsedContent = parser.parseFromString(html, 'text/html');

  return flattenNodes(parsedContent.body).slice(1)
    .map(stringifyNode)
    .reduce(splitTextNode, [])
    .map(trim)
    .filter(isNotEmpty)
    .map(renderLine)
    .join('');
};


const flattenNodes = (node, level = -1) => [
  { node, type: node.nodeType, level },
  ...Array.from(node.childNodes)
    .map(node => flattenNodes(node, level + 1))
    .flat()
];


const stringifyNode = ({ level, node, type }) => {
  const value =
    type === Node.ELEMENT_NODE ? stringifyElement(node) :
    type === Node.TEXT_NODE ? stringifyText(node) :
    '';
  return { level, type, value };
};


const stringifyElement = element => {
  return stringifyTagName(element) +
    stringifyId(element) +
    stringifyClasses(element) +
    stringifyAttributes(element);
};


const stringifyTagName = element => {
  const tagName = element.tagName.toLowerCase();
  return tagName === 'div' && element.attributes.length ? '' : tagName;
};


const stringifyId = element => element.id ? `#${element.id}` : '';


const stringifyClasses = element => [...element.classList].map(name => `.${name}`).join('');


const stringifyAttributes = element => {
  const attributes = [...element.attributes].filter(({ name }) => name !== 'id' && name !== 'class');
  if (!attributes.length) { return ''; }

  const stringifiedAttributes = attributes
    .map(({ name, value }) => value ? `${name}='${escapeAttributeValue(value)}'` : name)
    .join(', ');
  return `(${stringifiedAttributes})`;
};


const escapeAttributeValue = value => value.replace(/'/g, '\\\'');


const stringifyText = text => text.data;


const splitTextNode = (nodes, node) => {
  return nodes.concat(
    node.type === Node.TEXT_NODE ?
      node.value.split('\n').map(textChunk => ({ ...node, value: textChunk })) :
      [node]
  );
};


const trim = node => ({ ...node, value: node.value.trim() });


const isNotEmpty = ({ value }) => value;


const renderLine = ({ level, value, type }) =>
  ' '.repeat(level * 2) +
  (type === Node.TEXT_NODE ? '| ' : '') +
  value +
  '\n';
