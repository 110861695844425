import { mapGetters } from 'vuex';
import GoogleSignInButton from '../google-sign-in-button/index.vue';

export default {
  components: { GoogleSignInButton },

  computed: {
    ...mapGetters(['isSignedIn']),

    snippets() {
      if (!this.$store.state.snippets) {
        return [];
      }

      return this.$store.state.snippets.map(snippet => ({
        ...snippet,
        public: snippet.shared ? '<e-icon icon="check" color="inherit" class="text-color-success"></e-icon>' : ''
      }));
    }
  },

  methods: {
    create() {
      this.$store.dispatch('navigateToSnippet', '');
    },

    edit(event) {
      const snippet = event.detail.data;
      this.$store.dispatch('navigateToSnippet', snippet._id);
    },

    share(event) {
      const snippet = event.detail.data;
      this.globalEmitter.emit('openShareDialog', snippet);
    },

    remove(event) {
      const snippet = event.detail.data;
      window.e.utils.openDestructiveConfirmationDialog({
        headline: 'Delete snippet',
        content: `Are you sure you want to delete the snippet "${snippet.name}"?`,
        confirm: {
          label: 'Delete',
          callback: () => this.$store.dispatch('deleteSnippet', snippet._id)
        }
      });
    }
  }
};
