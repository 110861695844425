import { mapGetters, mapState } from 'vuex';
import { GlobalEvents } from '../../utils/event-emitter';

export default {
  data() {
    return {
      editor: null,
      isCreateMode: false,
      position: 'before'
    };
  },

  computed: {
    ...mapState(['activeUserScriptId']),
    ...mapGetters(['activeUserScript']),

    userScriptList() {
      const userScriptList = this.$store.state.userScriptList.map(userScript => {
        userScript.positionText = userScript.position === 'before' ? 'Start of head tag' : 'End of body tag';
        return userScript;
      });

      return userScriptList;
    },
    isEditMode() {
      return !!this.$store.state.activeUserScriptId || this.isCreateMode;
    },
    loadedData() {
      return this.activeUserScript ? this.activeUserScript : {
        name: 'New User Script',
        content: '',
        position: 'before'
      };
    }
  },

  watch: {
    activeUserScriptId() {
      this.editor.setValue(this.loadedData.content);
    }
  },

  methods: {
    onResize() {
      this.editor.layout();
    },

    create() {
      const data = {
        _id: false,
        name: 'New User Script',
        content: ''
      };

      this._initEditor(data);
    },

    edit(event) {
      this._initEditor(event.detail.data);
    },

    remove(event) {
      const data = event.detail.data;
      window.e.utils.openDestructiveConfirmationDialog({
        headline: 'Delete user script',
        content: `Are you sure you want to delete the user script "${data.name}"?`,
        confirm: {
          label: 'Delete',
          callback: () => this.$store.dispatch('deleteUserScript', data._id)
        }
      });
    },

    onBackButtonClick() {
      if (this.isSaveDisabled) { return; }

      this.isCreateMode = false;
      this.$store.commit('setActiveUserScriptId', null);
    },

    onSaveButtonClick() {
      const data = {
        ...this.loadedData,
        name: this.$refs.inplaceEditor.value,
        position: this.$refs.positionSelect.value,
        content: this.editor.getValue()
      };

      this.$store.dispatch('saveUserScript', data);
    },

    onInsertScriptSnippetButtonClick() {
      this.globalEmitter.emit(GlobalEvents.INSERT_SCRIPT_SNIPPET_DIALOG_OPEN);
    },

    changePosition(event) {
      this.position = event.target.value;
    },

    _initEditor(data) {
      if (data._id === false) {
        this.isCreateMode = true;
      }

      this.$store.commit('setActiveUserScriptId', data._id);
      this.editor.setValue(data.content);

      this.position = data.position || 'before';

      this.$nextTick(() => {
        this.editor.layout();
      });
    },

    _insertScriptSnippetDialog() {
      this.globalEmitter.emit(GlobalEvents.INSERT_SCRIPT_SNIPPET_DIALOG_OPEN);
    }
  },

  mounted() {
    if (this.editor) { return; }

    this.editor = window.monaco.editor.create(this.$refs.container, {
      language: 'javascript',
      value: this.loadedData.content,
      minimap: { enabled: false },
      tabSize: 2
    });

    this.editor.addAction({
      id: 'insertScriptSnippet',
      label: 'Insert Script Snippet',
      keybindings: [window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.KEY_I],
      contextMenuGroupId: '9_insertscriptsnippet',
      run: () => this._insertScriptSnippetDialog()
    });

    this.$store.commit('setScriptEditor', this.editor);
  }
};
