import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['isCodeExpanded'])
  },

  methods: {
    ...mapMutations(['toggleCodeExpansion'])
  }
};
