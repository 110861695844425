import { mapGetters } from 'vuex';
import Title from '../title/index.vue';
import LeftPanel from '../left-panel/index.vue';
import RightPanel from '../right-panel/index.vue';
import ProfileMenu from '../profile-menu/index.vue';
import PrototypeButton from '../prototype-button/index.vue';
import SaveButton from '../save-button/index.vue';
import ShareButton from '../share-button/index.vue';
import ShareDialog from '../share-dialog/index.vue';
import PromptNewNameDialog from '../prompt-new-name-dialog/index.vue';
import PromptLoginForSaveDialog from '../prompt-login-for-save-dialog/index.vue';
import InsertComponentDialog from '../insert-component-dialog/index.vue';
import InsertScriptSnippetDialog from '../insert-script-snippet-dialog/index.vue';
import sessionMixin from '../../mixins/session.js';
import hashHandlerMixin from '../../mixins/hash-handler';
import keyboardShortcutMixin from '../../mixins/keyboard-shortcuts';

export default {
  mixins: [sessionMixin, hashHandlerMixin, keyboardShortcutMixin],
  components: {
    Title,
    LeftPanel,
    RightPanel,
    ProfileMenu,
    PrototypeButton,
    SaveButton,
    ShareButton,
    ShareDialog,
    PromptNewNameDialog,
    PromptLoginForSaveDialog,
    InsertComponentDialog,
    InsertScriptSnippetDialog
  },
  computed: {
    ...mapGetters(['snippetIsDirty'])
  },

  mounted() {
    window.addEventListener('beforeunload', event => {
      if (this.snippetIsDirty) {
        const confirmMessage = 'You have unsaved changes. Are you sure you want to leave the page?';

        event.returnValue = confirmMessage;
        return confirmMessage;
      }
    });
  }
};
