import { mapActions, mapMutations } from 'vuex';
import convertHtmlToPug from '../../utils/convert-html-to-pug';
import { GlobalEvents } from '../../utils/event-emitter';

export default {
  data() {
    return {
      remoteCodeSamples: false
    };
  },
  methods: {
    ...mapActions(['reloadPreview']),
    ...mapMutations(['setIsPreviewSafe']),
    open() {
      this.$refs.dialog.open();

      if (!this.remoteCodeSamples) {
        fetch('https://designsystem.emarsys.net/code-examples.json')
          .then(response => response.json())
          .then(snippets => this.remoteCodeSamples = snippets.data);
      }
    },

    close() {
      this.$refs.dialog.close();
    },

    insertComponent(event) {
      const component = event.detail.data;
      const editor = this.$store.state.editor;
      const selection = editor.getSelection();
      const id = { major: 1, minor: 1 };

      let fullExample = component['code-example'];

      if (component['code-javascript']) {
        fullExample += `
<script>
  ${component['code-javascript']}
</script>
`;
        this.setIsPreviewSafe(false);
      }
      const text = this.$store.state.language === 'pug' ? convertHtmlToPug(fullExample) : fullExample;
      const insertTemplateOperation = { identifier: id, range: selection, text, forceMoveMarkers: true };

      editor.executeEdits('my-source', [insertTemplateOperation]);
      editor.focus();

      this.close();
      this.reloadPreview();
    }
  },

  mounted() {
    this.globalEmitter.subscribe(GlobalEvents.INSERT_COMPONENT_DIALOG_OPEN, this.open);
  },

  beforeDestroy() {
    this.globalEmitter.unsubscribe(GlobalEvents.INSERT_COMPONENT_DIALOG_OPEN, this.open);
  }
};
