import { mapActions } from 'vuex';

export default {
  props: {
    primary: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ...mapActions(['openLogin'])
  }
};
