export default ({ uiFrameworkUrl, userScriptListBefore }) => `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <title></title>
    ${userScriptListBefore.map(userScript => `<script>${userScript.content}</script>`)}
    <link rel='stylesheet' href='${uiFrameworkUrl}/css/app.css'>
    <script src='${uiFrameworkUrl}/js/test-setup.js'></script>
    <script src='${uiFrameworkUrl}/js/app.js'></script>
  </head>
  <body></body>
</html>
`;
