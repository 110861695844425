import { mapState } from 'vuex';
import { GlobalEvents } from '../../utils/event-emitter';

export default {
  data() {
    return {
      editor: null,
      currentValue: ''
    };
  },

  computed: {
    ...mapState(['sourceCode', 'language'])
  },

  watch: {
    sourceCode(sourceCode) {
      if (sourceCode !== this.currentValue) {
        this.currentValue = sourceCode;
        this.editor.setValue(sourceCode);
      }
    },

    language(language) {
      window.monaco.editor.setModelLanguage(this.editor.getModel(), language);
    }
  },

  methods: {

    onResize() {
      this.editor.layout();
    },

    onThemeChange(theme) {
      this.editor.updateOptions({ theme: this._isDarkTheme(theme) ? 'vs-dark' : 'vs' });
    },

    _isDarkTheme(currentTheme) {
      return ['eveningHorizon'].includes(currentTheme);
    },

    _onContentChange() {
      const value = this.editor.getValue();
      if (this.currentValue === value) { return; }
      this.currentValue = value;
      this.$store.commit('setSourceCode', value);
      this.$store.commit('setIsSaved', false);
      this.$store.commit('setSnippetIsDirty', true);
    },

    _insertText(text) {
      const selection = this.editor.getSelection();
      const id = { major: 1, minor: 1 };
      const op = { identifier: id, range: selection, text: text, forceMoveMarkers: true };
      this.editor.executeEdits('my-source', [op]);
    },

    _insertComponentDialog() {
      this.globalEmitter.emit(GlobalEvents.INSERT_COMPONENT_DIALOG_OPEN);
    }
  },

  mounted() {
    this.editor = window.monaco.editor.create(this.$refs.container, {
      theme: this._isDarkTheme(window.e.colorTheme) ? 'vs-dark' : 'vs',
      language: this.language,
      value: this.sourceCode,
      minimap: { enabled: false },
      tabSize: 2
    });

    this.editor.addAction({
      id: 'insertComponent',
      label: 'Insert Component',
      keybindings: [window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.KEY_I],
      contextMenuGroupId: '9_insertcomponent',
      run: () => this._insertComponentDialog()
    });

    this.globalEmitter.subscribe('insertNavigateFunction', this._insertText);
    this.globalEmitter.subscribe('insertJsonDataFunction', this._insertText);
    this.globalEmitter.subscribe(GlobalEvents.UI_THEME_CHANGE, this.onThemeChange);

    new ResizeObserver(this.onResize).observe(this.$refs.container);
    this.editor.getModel().onDidChangeContent(this._onContentChange);

    this.$store.commit('setEditor', this.editor);
  }
};
