import { mapState, mapGetters, mapActions } from 'vuex';
import OpenInNewWindowButton from '../open-in-new-window-button/index.vue';
import PreviewSettingsButton from '../preview-settings-button/index.vue';
import PreviewWarning from '../preview-warning/index.vue';
import UiKitPreview from '../ui-kit-preview/index.vue';

export default {
  computed: {
    ...mapState(['isPreviewSafe', 'isCodeExpanded']),
    ...mapGetters(['hidePreviewBorder', 'ctrlOrCmd'])
  },

  methods: {
    ...mapActions(['reloadPreview', 'navigateToSnippet'])
  },

  components: { OpenInNewWindowButton, PreviewSettingsButton, PreviewWarning, UiKitPreview }
};
