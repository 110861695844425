import { mapState } from 'vuex';

import config from '../../config';

export default {
  computed: {
    ...mapState(['name'])
  },

  watch: {
    name() {
      document.title = [this.$store.state.name, config.pageTitle].filter(part => part).join(' - ');
    }
  },

  methods: {
    changeName(newName) {
      if (!newName) { return; }
      this.$store.commit('setName', newName);
      this.$store.commit('setIsSaved', false);
    }
  }
};
