import { pick } from 'ramda';
import convertPugToHtml from '../utils/convert-pug-to-html';
import { createDefaultState } from './state';

export const mutations = {
  setName(state, name) {
    state.name = name;
  },

  setSourceCode(state, sourceCode) {
    state.sourceCode = sourceCode;

    if (state.language === 'html') {
      state.compiledCode = sourceCode;
      state.compileError = null;
    } else {
      try {
        state.compiledCode = convertPugToHtml(sourceCode);
        state.compileError = null;
      } catch (error) {
        state.compileError = pick(['code', 'column', 'line', 'msg', 'message'], error);
      }
    }

    state.compiledCode = state.compiledCode.replace(/"\#\{jsonDataList\[\'([a-z0-9]+)\'\]\}"/ig, (result, capture) => {
      const jsonData = state.jsonDataList && state.jsonDataList.find(jsonData => jsonData._id === capture);
      return jsonData ? `'${jsonData.content}'` : result;
    });
  },

  setLanguage(state, language) {
    state.language = language;
  },

  setIsCodeExpanded(state, value) {
    state.isCodeExpanded = value;
  },

  toggleCodeExpansion(state) {
    state.isCodeExpanded = !state.isCodeExpanded;
  },

  setSession(state, { expires, userId, name }) {
    state.session.expires = expires;
    state.session.userId = userId;
    state.session.name = name;
  },

  setActiveSnippetId(state, id) {
    state.activeSnippetId = id;
  },

  setSnippets(state, snippets) {
    state.snippets = snippets;
  },

  upsertSnippet(state, snippet) {
    const storedSnippetIndex = state.snippets.findIndex(storedSnippet => storedSnippet._id === snippet._id);
    if (storedSnippetIndex > -1) {
      state.snippets[storedSnippetIndex] = snippet;
    } else {
      state.snippets.push(snippet);
    }
    state.snippets = [...state.snippets];
  },

  removeSnippet(state, id) {
    state.snippets = state.snippets.filter(snippet => snippet._id !== id);
    if (state.activeSnippetId === id) { state.activeSnippetId = null; }
  },

  setShared(state, { id, shared }) {
    if (!state.snippets) { return; };
    const snippet = state.snippets.find(snippet => snippet._id === id);
    snippet.shared = shared;
    state.snippets = [...state.snippets];
  },

  setIsPreviewSafe(state, value) {
    state.isPreviewSafe = value;
  },

  setIsSaveLoading(state, value) {
    state.isSaveLoading = value;
  },

  setIsSaved(state, value) {
    state.isSaved = value;
  },

  setEditor(state, reference) {
    state.editor = reference;
  },

  setScriptEditor(state, reference) {
    state.scriptEditor = reference;
  },

  setIncludedScripts(state, value) {
    state.includedScripts = { ...state.includedScripts, ...value };
  },

  setJsonDataList(state, jsonDataList) {
    state.jsonDataList = jsonDataList;
  },

  upsertJsonData(state, jsonData) {
    const storedJsonDataIndex = state.jsonDataList.findIndex(storedJsonData => storedJsonData._id === jsonData._id);

    if (storedJsonDataIndex > -1) {
      jsonData.modifiedAt = new Date().toISOString();
      state.jsonDataList[storedJsonDataIndex] = jsonData;
    } else {
      jsonData.createdAt = new Date().toISOString();
      jsonData.modifiedAt = jsonData.createdAt;
      state.jsonDataList.push(jsonData);
    }

    state.jsonDataList = [...state.jsonDataList];
    state.activeJsonDataId = jsonData._id;
  },

  removeJsonData(state, id) {
    state.jsonDataList = state.jsonDataList.filter(jsonData => jsonData._id !== id);
    if (state.activeJsonDataId === id) { state.activeJsonDataId = null; }
  },

  setActiveJsonDataId(state, id) {
    state.activeJsonDataId = id;
  },

  setScriptSnippets(state, snippets) {
    state.scriptSnippetList = snippets;
  },

  upsertScriptSnippet(state, snippet) {
    const storedScriptSnippetIndex = state.scriptSnippetList.findIndex(storedScriptSnippet => storedScriptSnippet._id === snippet._id);

    if (storedScriptSnippetIndex > -1) {
      snippet.modifiedAt = new Date().toISOString();
      state.scriptSnippetList[storedScriptSnippetIndex] = snippet;
    } else {
      snippet.createdAt = new Date().toISOString();
      snippet.modifiedAt = snippet.createdAt;
      state.scriptSnippetList.push(snippet);
    }

    state.scriptSnippetList = [...state.scriptSnippetList];
    state.activeScriptSnippetId = snippet._id;
  },

  removeScriptSnippet(state, id) {
    state.scriptSnippetList = state.scriptSnippetList.filter(scripSnippet => scripSnippet._id !== id);
    if (state.activeScriptSnippetId === id) { state.activeScriptSnippetId = null; }
  },

  setActiveScriptSnippetId(state, id) {
    state.activeScriptSnippetId = id;
  },

  setUserScriptList(state, userScriptList) {
    state.userScriptList = userScriptList;
  },

  upsertUserScript(state, userScript) {
    const storedUserScriptIndex = state.userScriptList.findIndex(storedUserScript => storedUserScript._id === userScript._id);

    if (storedUserScriptIndex > -1) {
      userScript.modifiedAt = new Date().toISOString();
      state.userScriptList[storedUserScriptIndex] = userScript;
    } else {
      userScript.createdAt = new Date().toISOString();
      userScript.modifiedAt = userScript.createdAt;
      state.userScriptList.push(userScript);
    }

    state.userScriptList = [...state.userScriptList];
    state.activeUserScriptId = userScript._id;
  },

  removeUserScript(state, id) {
    state.userScriptList = state.userScriptList.filter(userScript => userScript._id !== id);
    if (state.activeUserScriptId === id) { state.activeUserScriptId = null; }
  },

  setActiveUserScriptId(state, id) {
    state.activeUserScriptId = id;
  },

  setSnippetIsDirty(state, value) {
    state.snippetIsDirty = value;
  }
};

const state = createDefaultState();
Object.keys(state.previewSettings).forEach(name => {
  mutations[`setPreviewSettings${name.charAt(0).toUpperCase()}${name.substring(1)}`] = function(state, value) {
    state.previewSettings[name] = value;
  };
});
