import convertPugToHtml from '../../utils/convert-pug-to-html';
import convertHtmlToPug from '../../utils/convert-html-to-pug';
import formatHtml from '../../utils/format-html';

import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['language'])
  },

  methods: {
    switchLanguage(event) {
      const language = event.target.value;
      this.$store.commit('setLanguage', language);
      if (language === 'pug') {
        const sourceCode = convertHtmlToPug(this.$store.state.sourceCode);
        this.$store.commit('setSourceCode', sourceCode);
      } else {
        const html = convertPugToHtml(this.$store.state.sourceCode);
        const sourceCode = formatHtml(html);
        this.$store.commit('setSourceCode', sourceCode);
      }
      this.$store.commit('setIsSaved', false);
    }
  }
};
