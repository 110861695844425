export const createDefaultState = () => ({
  name: '',
  sourceCode: '',
  compiledCode: '',
  compileError: null,
  isCodeExpanded: false,
  language: 'pug',
  snippets: null,
  activeSnippetId: null,
  jsonDataList: [],
  activeJsonDataId: null,
  scriptSnippetList: [],
  activeScriptSnippetId: null,
  userScriptList: [],
  activeUserScriptId: null,
  isPreviewSafe: true,
  isSaveLoading: false,
  isSaved: false,
  snippetIsDirty: false,
  includedScripts: createDefaultIncludedScripts(),
  session: {
    expires: null,
    userId: null,
    name: ''
  },
  previewSettings: {
    showBorder: true,
    reloadScriptsOnChange: false
  },
  editor: null,
  scriptEditor: null
});

export const createDefaultIncludedScripts = () => ({
  alpineJs: true,
  uiFrameworkCharts: false,
  uiFrameworkNavigation: false,
  vcePreview: false
});
