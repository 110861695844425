import { IframeMessage } from './iframe-message';
import { IframeResponseTimeoutError } from './iframe-response-timeout-error';


export const getSnippetCodeFromIframe = async (url, sourceId) => {
  const cleanUpFunctions = [];
  return Promise.race([
    scheduleError(new IframeResponseTimeoutError(), 30000),
    (async () => {
      const iframe = createIframe(url);
      const iframeLoaded = waitForLoad(iframe);
      cleanUpFunctions.push(() => iframe.remove());
      document.body.appendChild(iframe);
      await iframeLoaded;
      return requestSnippet(iframe, sourceId, cleanUpFunctions);
    })()
  ]).finally(() => {
    cleanUpFunctions.forEach(fn => fn());
  });
};


const createIframe = url => {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  return iframe;
};


const waitForLoad = iframe => new Promise(resolve => iframe.addEventListener('load', resolve));


const requestSnippet = async (iframe, sourceId, cleanUpFunctions) => {
  const responseArrived = waitForResponse(iframe, sourceId, cleanUpFunctions);
  const message = IframeMessage.create(IframeMessage.GET_SNIPPET, { sourceId });
  message.postOn(iframe.contentWindow);
  return responseArrived;
};


const waitForResponse = (iframe, sourceId, cleanUpFunctions) => new Promise(resolve => {
  const onMessage = (event) => {
    const message = IframeMessage.fromEvent(event);
    const isRelevantMessage = event.source === iframe.contentWindow &&
      message.type === IframeMessage.RECEIVE_SNIPPET &&
      message.data?.sourceId === sourceId;
    if (!isRelevantMessage) { return; }

    resolve(message.data.snippet);
  };
  cleanUpFunctions.push(() => window.removeEventListener('message', onMessage));
  window.addEventListener('message', onMessage);
});


const scheduleError = (error, timeout) => new Promise((_, reject) => setTimeout(() => reject(error), timeout));
